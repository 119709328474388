
import { Options, Vue } from 'vue-class-component';
import { CartClient, CommunityClient, OrderClient} from '@/services/Services';
import * as OM from '@/model';
import ConfirmDeleteOrder from '@/components/confirmDeleteOrder.vue';
import { CommonServices } from '@/services/CommonServices';
import { LocalizationServices } from '@/services/LocalizationServices';




@Options({
    components: {
        ConfirmDeleteOrder
    }
})
@Options({})
export default class CartSettings extends Vue {

    communityIdentifier: string = "";
    model: OM.CartVM = new OM.CartVM();

    created() {
        CommonServices.showSpinnerCb();

        CommunityClient.getBySlug(this.$route.params.slug.toString())
        .then(x => {
            this.communityIdentifier = x.identifier;
            CartClient.getCartDetail(this.communityIdentifier)
            .then(x => {
                this.model = x;
            }).finally( () => {
                CommonServices.hideSpinnerCb();
            })
        }).catch ( err => {
            CommonServices.hideSpinnerCb();
        })
    }

    clearCart() {
        var text = LocalizationServices.getLocalizedValue("app_vuoieliminarecarrello", "Sei sicuro di voler eliminare tutti i prodotti nel carrello?");
        this.$opModal.show(ConfirmDeleteOrder ,{
            text: text,
            okCb: () => {
                CartClient.emptyCart(this.communityIdentifier)
                .then(x => {
                    
                    this.$opModal.closeAll();
                    this.$router.back();
                })
            },
            koCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    save() {
        var vm = new OM.ChangeCartAmountVm();
        vm.communityIdentifier = this.communityIdentifier;

        this.model.cartItems.forEach(element => {
            var itemVM = new OM.ItemAmountVM();
            itemVM.cartItemIdentifier = element.variation.identifier;
            itemVM.amount = element.amount;
            vm.cartItems.push(itemVM);
        });

        var proms = [];
        proms.push(CartClient.setNotes(this.model.cartIdentifier, this.model.notes));
        proms.push(CartClient.changeAllItemsAmount(vm));

        Promise.all(proms)
        .then(x => {
            this.$router.back();
        })
    }

    minusAmount(item: OM.CartItemVM){
        if(item.amount == 0)
            return;

        item.amount --;
    }

}
